<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-img height="100vh" class="absolute left-0 top-0" src="https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Ffundo.jpeg?alt=media&token=ec5e4a05-bb7e-4eee-838d-36449b4c7619"></v-img>
        <v-toolbar dark :color="'#5c0154'">
          <v-btn icon @click="dialog = false" color="white">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Formulário de inscrição - UCDs - 2022/1</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn color="white" text @click="dialog = false">
              Fechar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container fluid class="">
          <v-row dense no-gutters justify="center" align="center" class="m-0 p-0">
            <v-col cols="11" md="7" class="m-0 p-0">
              <stepers />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import stepers from "./stepers.vue";

export default {
  name: "inscricaoucd",
  components: { stepers },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      dialog: false,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
    },

    show() {
      this.dialog = true;
    }
  }
}
</script>

<style scoped>
</style>
