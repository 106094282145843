<template>
  <div class="">
    <v-card class="" height="620" style="overflow-y: auto; overflow-x: hidden">
      <FormulateForm @submit="submit" v-model="formValues" :schema="schema" />
    </v-card>
  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';

export default {
  name: "forms",
  components: {},
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      schema: [
        { style: "width: 100%;", class: 'm-0 f-raleway fw-700', component: "img", src: 'https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Ffundos%2Fbanner-recursos.png?alt=media&token=dce0d150-0287-41bf-8063-9c82ea94c9ee' },
        { class: 'm-2 f-raleway fs-11pt text-justify fw-300', component: "p", children: "Estudante, este formulário se destina única e exclusivamente ao registro de recursos referentes às questões da Avaliação A2 2021/2." },
        { class: 'm-2 f-raleway fs-11pt text-justify fw-300', component: "p", children: "Conforme previsto em edital, o prazo para solicitar a revisão de uma questão é de 24 horas, iniciado após a divulgação do gabarito preliminar da Unidade Curricular. A Comissão Avaliadora analisará apenas as solicitações que forem realizadas por meio deste formulário." },
        { class: 'm-2 f-raleway fs-11pt fw-300', component: "p", children: "Para ser analisado, o recurso deve possuir todos os campos corretamente preenchidos, sob pena de indeferimento. O endereço de email informado será utilizado para lhe informar se o recurso foi aceito ou negado, bem como para lhe enviar uma cópia das respostas que você forneceu." },
        { class: 'm-2 f-raleway fs-11pt text-justify fw-300', component: "hr", children: "" },
        { class: 'm-2 mt-3 mb-2', type: "text", help: "Digite seu número de registro acadêmico", name: "ra", label: "RA (Número de Registro Acadêmico)", validation: "required" },
        { class: 'm-2 mt-3 mb-2', type: "submit" },
      ],
      formValues: { nome: "oi" },
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
    },

    submit () {
      alert('passed validation')
    }
  }
}
</script>

<style scoped>
</style>
