
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import moment from 'moment';
import { faker } from '@/components/dummy/faker.js'
import { $vue } from '@/main.js'

export const notification  = {
  send: function(info) {
    console.log("Notification send...");
    var newKey = rdb.ref('/notification').push().key;
    console.log("put com nova chave: "+newKey);
    var itemClone = Object.assign({}, info);
    itemClone.id = newKey;
    var updates = {};
    console.log('/notification/' + newKey);
    updates['/notification/' + newKey] = itemClone;
    rdb.ref().update(updates);
    console.log("notification saved!");
  },

  getUser: function() {
    var user = $vue.$session.get("user");
    if(user == undefined) return faker.getUser();
    return user;
  },

  getDataInfoApp: function(mensagem = "sem mensagem", path = "") {
    return {
      createdAt: moment().format(),
      nomeFrom: "AppIntegra",
      urlFoto: "https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Flogos%2Flogo-icone.png?alt=media",
      type: "avatar",
      toUserID: "",
      fromUserID: "AppIntegra",
      readed: false,
      path: path,
      mensagem: mensagem,
    };
  },

  getDataInfo: function(mensagem = "sem mensagem", path = "") {
    var user = $vue.$session.get("user");
    if(user == undefined) user = faker.getUser();
    return {
      createdAt: moment().format(),
      nomeFrom: user.NOME,
      urlFoto: user.urlFoto,
      type: "avatar",
      toUserID: "",
      fromUserID: user.id,
      readed: false,
      path: path,
      mensagem: mensagem,
    };
  },

  sendFake: function() {
    console.log("Notification Fake send...");
    var createdAt = moment().format();
    var info = {
      createdAt: createdAt,
      nome: faker.get("name","firstName") + " " + faker.get("name","lastName"),
      urlFoto: faker.profileImg(),
      type: "avatar",
      toUserID: "",
      fromUserID: "",
      readed: false,
      path: "",
      mensagem: "",
    };
    console.log(info);
    this.send(info);
  }


};
