<template>
  <span v-if="!blocked" v-cHex="color" class="col-12 col-lg-4 m-0 p-0 f-raleway fs-10pt fw-300 text-right">
    <v-btn @click="$emit('showComents',true)" class="m-0 p-0 ms-0 me-1" small icon>
      <i class="btn-icon-left" v-cHex="color" v-i:duo#chat-circle-text#18></i>
    </v-btn>
    <v-btn @click="$emit('showComents',true)" v-cHex="color" class="m-0 ms-n2 p-0 text-lowercase" small plain text style="letter-spacing: normal;">
      <span class="f-raleway fs-9pt fw-500">comentar</span>
    </v-btn>
  </span>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';

export default {
  name: "btncommentblock",
  components: {},
  props: {
    color: { default: "#000000", type: String },
    uc: { default: null, type: Object },
    area: { default: null, type: Object },
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      blocked: true,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //console.log("uc",this.uc);
    //console.log("this.isUserAdmin",this.isUserAdmin);

    if(this.area != null) {
      var gestores = ["Gestor(a) de Área","Assessor(a) de Área","Coordenador(a) Regional"];
      if(this.isUserAdmin || gestores.indexOf(this.$store.state.user.cargo) != -1 && this.$store.state.user.areasIC[this.area.id] != undefined) {
        self.blocked = false;
        return;
      }
    }
    if(this.isUserAdmin) {
      self.blocked = false;
    } else {
      if(this.uc != null) {
        rdb.ref('/curriculo/2021S2/ucsProfsRef/'+this.uc.id+"/professores/"+this.userLog.id).on('value', function(snapshot) {
          var ret = snapshot.val();
          //console.log("ret",ret);
          if(ret != null) {
            self.blocked = false;
          }
        });
      }
    }
  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
