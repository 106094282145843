import moment from 'moment';
import { dbmodel } from "./dbmodel.js";
import { apiAlunos } from "./api-alunos.js";
import { rdb, db, ServerValue, snapshotValToArray, generatePushID } from '@/firebasedb.js'
const Handlebars = require("handlebars");
import XLSX from 'xlsx';

export const pluginTool = {

  parseScriptToObj(obj) {

    var objScript = {};
    //console.log("parseScriptToObj",obj);
    for(var key in obj) {
      if(obj[key].function != undefined) {
        //console.log("function "+key,obj[key]);
        if(key == 'props') {
          var f = obj[key];
          var func = new Function(f.params.join(','),f.body);
          //console.log(func());
          objScript[key] = func();
        } else {
          if(key == 'computed') {
            var f = obj[key];
            var func = new Function(f.params.join(','),f.body);
            //console.log(func());
            objScript[key] = func();
          } else {
            if(key == 'watch') {
              var f = obj[key];
              var func = new Function(f.params.join(','),f.body);
              //console.log(func());
              objScript[key] = func();
            } else {
              var f = obj[key];
              objScript[key] = new Function(f.params.join(','),f.body);
            }
          }
        }
      } else {
        if(key == "methods") {
          objScript[key] = {};
          for(var key2 in obj[key]) {
            if(obj[key][key2].function) {
              //console.log("METHODS function "+key2,obj[key][key2]);
              var f = obj[key][key2];
              objScript[key][key2] = new Function(f.params.join(','),f.body);
            }
          }
        }
        if(key == "props") {
          console.log("obj[key]",obj[key]);
        }
      }
    };
    objScript.created = function() {
      var self = this;
      //console.log("component plugin CREATED!");
      this.$emit('iniciar', {
        cbfunc: function(dados,rdb) {
          //console.log("cbfunc rdb",rdb);
          //self.super = dados;
          //self.super.rdb = rdb;
          self.appIntegra = {
            moment, dbmodel, apiAlunos, Handlebars, XLSX,
            ServerValue, snapshotValToArray, generatePushID,
            "_api": function(token, escope) {
              if(token == "ZIXNCTVEBGNRMA") {
                if(escope == "ZIXNCTVEBGNRMA") {
                  return rdb.ref();
                }
                if(escope == undefined || escope == "") {
                  escope = "sem-escopo";
                }
                return rdb.ref("/appIntegra/pluginsEscope/"+escope);
              }
            },
            sendMail: function(dados,cbfunc) {
              db.collection('mail2').add(dados).then(function() {
                //console.log("email enviado!");
                cbfunc();
              });
            }
          }
        }
      })
    };

    //console.log("objScript",objScript);
    return objScript;
  }
}
