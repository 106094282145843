<template>
  <div class="">
    <v-card class="mb-4 pb-3 card-scroll" height="410px" color="" >
      <v-card class="mx-4 py-1 px-2 my-3 card-scroll" maxHeight="160px">
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <p class="fs-10pt fw-500 mt-0 mb-1">UCDs Selecionadas - 2022/1</p>
              <v-alert type="info" class="py-1 px-2" v-if="Object.keys(ucs20221Sel).length == 0">
                Nenhuma UCD selecionada.
              </v-alert>
              <ucchip :uc="key" v-for="(uc,key,index) in ucs20221Sel" :key="key"/>
            </v-col>
            <v-col cols="12" md="6">
              <p class="fs-10pt fw-500 mt-0 mb-1">UCDs Selecionadas - 2022/2</p>
              <v-alert type="info" class="py-1 px-2" v-if="Object.keys(ucs20222Sel).length == 0">
                Nenhuma UCD selecionada.
              </v-alert>
              <ucchip :uc="key" v-for="(uc,key,index) in ucs20222Sel" :key="key"/>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-container>
        <v-row no-gutters align="center" justify="start">
          <v-col cols="auto">
            <v-icon class="ms-0 me-0 material-icons-two-tone fs-20pt">school</v-icon>
          </v-col>
          <v-col class="ms-2 fw-300 text-left fw-600 fs-12pt f-lato">
            Selecione as UCs que você possui aderência e pretende se candidatar como docente:
          </v-col>
        </v-row>
      </v-container>
      <div class="mx-auto w420">
        <v-tabs v-model="tab" class="mx-4" height="28" dense>
          <v-tab class="text-nonecase">UCDs Ofertadas em 2022/1</v-tab>
          <v-tab class="text-nonecase">UCDs Ofertadas em 2022/2</v-tab>
        </v-tabs>
      </div>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card class="mx-4 mt-2" :color="'#dddddd'">
            <v-container fluid>
              <v-row dense align="center" justify="start" class="m-0 p-0">
                <v-col cols="12" md="12" class="m-0 p-0">
                  <v-text-field class="m-0 p-0 mb-2" v-model="search1" append-icon="mdi-magnify" label="Filtrar UCDs" solo dense hide-details></v-text-field>
                  <v-data-table dense :headers="headers1" :items="ucs20221" :search="search1" :items-per-page="10">
                    <template v-slot:item="{ item }">
                      <tr class="fs-9pt">
                        <td class="fs-9pt">
                          <span v-for="(area, key, index) in item.areas" :key="key">
                            <tagarea class="me-1" :sigla="area.sigla"/>
                          </span>
                        </td>
                        <td class="fs-9pt text-truncate">
                          <i v-i:duo#graduation-cap#20 class="btn-icon-left me-2"></i>
                          {{item.nome}}
                        </td>
                        <td>
                          <checkucsel :uc="item" field="ucs20221Sel" :key="item.id" />
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card class="mx-4 mt-2" :color="'#dddddd'">
            <v-container fluid>
              <v-row dense align="center" justify="start" class="m-0 p-0">
                <v-col cols="12" md="12" class="m-0 p-0">
                  <v-text-field class="m-0 p-0 mb-2" v-model="search2" append-icon="mdi-magnify" label="Filtrar UCDs" solo dense hide-details></v-text-field>
                  <v-data-table dense :headers="headers1" :items="ucs20222" :search="search2" :items-per-page="10">
                    <template v-slot:item="{ item }">
                      <tr class="fs-9pt">
                        <td class="fs-9pt">
                          <span v-for="(area, key, index) in item.areas" :key="key">
                            <tagarea class="me-1" :sigla="area.sigla"/>
                          </span>
                        </td>
                        <td class="fs-9pt text-truncate">
                          <i v-i:duo#graduation-cap#20 class="btn-icon-left me-2"></i>
                          {{item.nome}}
                        </td>
                        <td>
                          <checkucsel :uc="item" field="ucs20222Sel" :key="item.id"/>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-btn class="ms-4 px-2 left" outlined small :color="'#8b70a1'" @click="$emit('anterior')">
      <v-icon class="ms-0 me-1 material-icons-two-tone">
        undo
      </v-icon>
      anterior
    </v-btn>
    <v-btn class="ms-4 px-2 right" outlined small :color="'#380f5c'" @click="proximo">
      Próximo
      <v-icon class="ms-0 ms-1 material-icons-two-tone">
        forward
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import tagarea from "./tagArea.vue";
import checkucsel from "./check-uc-sel.vue";
import ucchip from "./uc-chip.vue";

export default {
  name: "step01",
  components: { tagarea, checkucsel, ucchip },
  props: [],
  computed: {},
  data(){
    return {
      tab: null,
      loading: false,
      refresh: 0,
      search1: "",
      search2: "",
      ucs20221: [],
      ucs20222: [],
      headers1: [
        { text: 'Áreas', align: 'start', sortable: false, value: 'areasCol' },
        { text: 'Nome UCD', align: 'start', sortable: true, value: 'nome' },
        { text: '', align: 'start', sortable: false, value: 'actions' },
      ],
      ucs20221Sel: {},
      ucs20222Sel: {},
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    rdb.ref('/curriculo/ofertas/ucsLive').orderByChild("oferta2022S1").equalTo(true).on('value',function(snapshot) {
      self.ucs20221 = snapshotValToArray(snapshot.val());
      console.log("self.ucs20221",self.ucs20221);
      //self.build();
    });
    rdb.ref('/curriculo/ofertas/ucsLive').orderByChild("oferta2022S2").equalTo(true).on('value',function(snapshot) {
      self.ucs20222 = snapshotValToArray(snapshot.val());
      console.log("self.ucs20222",self.ucs20222);
      //self.build();
    });
    rdb.ref("/curriculo/2021S2/inscricoesUCD/"+this.userLog.id+"/ucs20221Sel").on('value',function(snapshot) {
      var ucs = snapshot.val();
      if(ucs != null) {
        self.ucs20221Sel = ucs;
        //console.log("self.ucs20222Sel",self.ucs20222Sel);
      } else {
        self.ucs20221Sel = {};
      }
    });
    rdb.ref("/curriculo/2021S2/inscricoesUCD/"+this.userLog.id+"/ucs20222Sel").on('value',function(snapshot) {
      var ucs = snapshot.val();
      if(ucs != null) {
        self.ucs20222Sel = ucs;
        //console.log("self.ucs20222Sel",self.ucs20222Sel);
      } else {
        self.ucs20222Sel = {};
      }
      //self.build();
    });

  },
  methods:{

    build() {
      var self = this;
    },

    proximo() {
      if(Object.keys(this.ucs20221Sel).length == 0 && Object.keys(this.ucs20222Sel).length == 0) {
        this.toastD("Indique pelo menos uma UCD!");
        return;
      }
      this.$emit("proximo");
    },

    remove (item) {
      console.log("item",item);
      console.log("this.areasSel",this.areasSel);
      let pos = -1;
      for(var key in this.areasSel) {
        console.log("this.areasSel[key]",this.areasSel[key]);
        console.log("item.id",item.id);
        if(this.areasSel[key] == item.id) {
          pos = key;
          break;
        }
      }
      if (pos >= 0) {
        this.areasSel.splice(pos, 1);
      }
      console.log("this.areasSel",this.areasSel);
    },

    selecionaUC1(item) {
      console.log(item);
      rdb.ref("/curriculo/2021S2/inscricoesUCD/"+this.userLog.id+"/ucs20221Sel/"+item.id).set(true);
    },

    selecionaUC2(item) {
      console.log(item);
    }
  }
}
</script>

<style scoped>
</style>
