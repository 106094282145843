<template>
  <span>
    <input type="file" ref="input1" style="display: none" @change="previewImage" :accept="accept" >
    <apploading :show="loading" :value="uploadValue" />
  </span>
</template>

<script>
import { fbstDummy01 } from '@/firebasedb-dummy01.js'
import apploading from "./appLoading.vue"

export default {
  name: "fileupload",
  components: { apploading },
  props: [ "path", "refreshModel", "accept", "maxsize" ],
  data () {
    return {
      progressUpload: 0,
      file: File,
      imageData: '',
      caption : '',
      img1: null,
      uploadValue: 0,
      loading: false,
    }
  },
  methods: {
    create () {
      console.log(this.caption)
    },
    upload() {
      this.$refs.input1.click()
    },

    previewImage(event) {
      this.uploadValue=0;
      this.img1=null;
      this.imageData = event.target.files[0];
      var filesize = event.target.files[0].size;
      console.log(filesize);
      console.log("event.target.files[0]",event.target.files[0]);
      console.log("this.maxsize",this.maxsize);
      var maxsize = this.maxsize;
      if(maxsize == undefined) {
        maxsize = 400000;
      }
      if(filesize > maxsize) {
        this.toastD("Falha no upload! Tamanho máximo do arquivo é de "+(maxsize/1000)+"Kb.");
        return;
      }
      this.onUpload()
    },

    onUpload(){
      var self = this;
      this.img1=null;
      const storageRef = fbstDummy01.ref(this.path+"/"+`${this.imageData.name}`).put(this.imageData);
      self.loading = true;
      storageRef.on('state_changed', snapshot => {
        this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error => {
        console.log(error.message)}, () => {
          self.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then( (url) => {
            this.img1 = url;
            self.$emit('urlUpload', url);
            self.loading = false;
            console.log("file uploade! ",self.img1)
          });
        }
      );
    },
  }
}
</script>

<style>
.progress-bar {
  margin: 10px 0;
}
</style>
