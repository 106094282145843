<template>
  <v-chip class="ma-2" label v-if="ucObj != null">
    <span v-for="(area, key, index) in ucObj.areas" :key="key">
      <tagarea class="me-1" :sigla="area.sigla"/>
    </span>
    <span class="fs-9pt text-truncate">
      <i v-i:duo#graduation-cap#20 class="btn-icon-left ms-1"></i>
      <span class="fs-9pt fw-500">{{ucObj.nome}}</span>
    </span>
  </v-chip>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import tagarea from "./tagArea.vue";

export default {
  name: "",
  components: { tagarea },
  props: {
    uc: { default: "xxxx", type: String },
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      ucObj: null,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    console.log(this.uc);
    rdb.ref("/curriculo/ucs/"+this.uc).on('value',function(snapshot) {
      self.ucObj = snapshot.val();
      //console.log("self.ucObj",self.ucObj);
      //self.build();
    });
  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
