<template>
  <div class="m-0 mb-2 p-0">
    <v-card outlined class="m-0 mb-2 pb-1 px-1">
      <div class="pt-2 pb-0 mx-2 my-0 text-left fs-10pt f-sanspro">
        <i v-i:duo#calendar#16></i>
        <span class="ms-1 fw-400">Calendário</span>
        <span class="ms-1 fw-300">(próximas datas)</span>
      </div>
      <div class="my-0 py-0">
        <div class="" v-if="loading">
          <v-skeleton-loader class="mt-2" type="divider, list-item-avatar"></v-skeleton-loader>
          <v-skeleton-loader class="mt-2" type="divider, list-item-avatar"></v-skeleton-loader>
          <v-skeleton-loader class="mt-2" type="divider, list-item-avatar"></v-skeleton-loader>
        </div>
        <v-list class="my-0 py-0" dense three-line v-if="!loading">
          <v-divider class="mx-1 my-0 py-0 mt-1"></v-divider>
          <div class="" v-for="(item,key,index) in eventos" :key="index">
            <v-list-item class="ms-2 ps-0" style="height: 50px; max-height: 50px; min-height: 50px;">
              <v-list-item-icon class="ms-0 me-2 pt-0 mt-1" small style="height: 40px; max-height: 40px; min-height: 40px;">
                <i v-i:duo#calendar#26 :style="'color: '+item.cor.hexa"></i>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="line-height-1 fw-300 fs-10pt">
                  <span class="fs-10pt f-sanspro fw-500" v-for="(setor,key,index) in item.setores">
                    <span v-if="index == 0" :style="'color: '+setor.cor.hexa">{{setor.nome}}</span>
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle class="line-height-1 text-truncate">
                  <span class="fs-11pt f-roboto fw-300">{{item.descricao}}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="line-height-1 fw-300 fs-10pt">
                  <span class="fs-9pt f-sanspro fw-600">{{$moment(item.dataInicio).format("DD/MM/YYY")}}</span>
                  <span class="mx-1">a</span>
                  <span class="fs-9pt f-sanspro fw-500">{{$moment(item.dataFinal).format("DD/MM/YYY")}}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mx-1 my-0 py-0 mt-2"></v-divider>
          </div>
        </v-list>
      </div>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import { rdb } from '@/firebasedb.js'
import moment from 'moment'
import { notification } from '@/components/notification/notification.js'
import dummy from '@/components/dummy/dummy.vue'
import { faker } from '@/components/dummy/faker.js'

export default {
  name: 'datasproximas',
  components: { dummy },
  computed: {
  },
  data () {
    return {
      dialog: false,
      loading: false,
      eventos: null,
    }
  },
  created() {
    this.log_s('Created');
  },
  mounted() {
    var self = this;
    window.moment = moment;
    this.log_s('Mounted');
    this.loadEventos();
  },

  methods: {

    loadEventos() {
      //console.log("loadEventos");
      var self = this;
      self.loading = true;
      var inicio = moment().format().split("T")[0];
      //console.log("inicio",inicio);
      var fim = moment().add(4, 'days').format().split("T")[0];
      //console.log("fim",fim);
      rdb.ref('/calendarios/2021S2/eventos/').orderByChild("dataInicio").startAt(inicio).endAt(fim+"\uf8ff").limitToFirst(5).on('value', function(snapshot) {
        self.eventos = snapshot.val();
        for(var key in self.eventos) {
          var setor = self.eventos[key].setores[Object.keys(self.eventos[key].setores)[0]];
          self.eventos[key].cor = setor.cor;
        }
        //console.log("self.eventos",self.eventos);
        self.loading = false;
      });
    }
  }
}
</script>
