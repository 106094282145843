
import {
  VApp, VCard, VCardActions, VCardSubtitle, VCardText, VCardTitle,
  VRow, VCol, VContainer, VBtn, VAvatar, VImg, VIcon, VAlert, VSpacer,
  VBadge, VBanner, VChip, VCheckbox, VChipGroup, VColorPicker, VCombobox,
  VContent, VCounter, VData, VDataTable, VDialog, VDivider, VExpansionPanel, VExpansionPanels,
  VFileInput, VExpansionPanelHeader, VExpansionPanelContent,
  VFooter, VForm, VHover, VInput, VLabel, VList, VMenu, VMessages,
  VNavigationDrawer, VOverlay, VPagination, VPicker, VProgressCircular,
  VProgressLinear, VRadio, VRadioGroup, VRangeSlider, VRating, VResponsive,
  VSelect, VSheet, VSkeletonLoader, VSlideGroup, VSlider, VSnackbar, VSparkline,
  VSpeedDial, VStepper, VStepperContent, VStepperHeader, VStepperItems, VStepperStep,
  VSwitch, VSystemBar, VTabs, VTab, VTabsItems, VTabItem,  VTextarea, VTextField, VTimeline, VTimelineItem,
  VDatePicker, VTimePicker, VToolbar, VToolbarTitle, VTooltip, VTreeview, VVirtualScroll, VWindow
} from 'vuetify/lib'

import appintegraplugin from "@/components/plugins2/app-integra-plugin.vue";
import fileupload from "./fileupload.vue";
import forms from "./forms/forms.vue";
import tagarea from "@/components/tagArea.vue";
import donut from "@/components/donut/components/Donut.vue";
import avataronline from "@/components/avatar-online.vue";

export function vuetifyComponents() {
  return {
    VApp, VCard, VCardActions, VCardSubtitle, VCardText, VCardTitle,
    VRow, VCol, VContainer, VBtn, VAvatar, VImg, VIcon, VAlert, VSpacer,
    VBadge, VBanner, VChip, VCheckbox, VChipGroup, VColorPicker, VCombobox,
    VContent, VCounter, VData, VDataTable, VDialog, VDivider, VExpansionPanel, VExpansionPanels,
    VFileInput, VExpansionPanelHeader, VExpansionPanelContent,
    VFooter, VForm, VHover, VInput, VLabel, VList, VMenu, VMessages,
    VNavigationDrawer, VOverlay, VPagination, VPicker, VProgressCircular,
    VProgressLinear, VRadio, VRadioGroup, VRangeSlider, VRating, VResponsive,
    VSelect, VSheet, VSkeletonLoader, VSlideGroup, VSlider, VSnackbar, VSparkline,
    VSpeedDial, VStepper, VStepperContent, VStepperHeader, VStepperItems, VStepperStep,
    VSwitch, VSystemBar, VTabs, VTab, VTabsItems, VTabItem, VTextarea, VTextField, VTimeline, VTimelineItem,
    VDatePicker, VTimePicker, VToolbar, VToolbarTitle, VTooltip, VTreeview, VVirtualScroll, VWindow,
    appintegraplugin, fileupload, forms, tagarea, "vc-donut": donut, avataronline
  }
};
