<template>
  <div class="">
    <!-- Card -->
    <v-card outlined class="mb-2">
      <!-- Img  -->
      <img :src="'https://placeimg.com/400/100/tech?update='+Math.random()" class="card-img-top rounded-top" alt="">
      <v-card-title class="text-left my-0 py-0">
        <div class="my-0 mt-2 py-0 line-height-3 fs-10pt" style="width: 100%">
          Evento
        </div>
        <div class="my-0 mt-1 py-0 line-height-3 f-raleway fw-600 fs-12pt">
          <dummy attr="lorem.sentence"/>
        </div>
      </v-card-title>
      <v-card-text class="mt-1 text-left text-justify line-height-3 f-raleway fw-300 fs-11pt">
        <dummy attr="lorem.sentence"/>
        <dummy attr="lorem.sentence"/>
        <dummy attr="lorem.sentence"/>
      </v-card-text>
      <v-row class="py-1">
        <div class="mx-4">
          <div class="left">
            <h5 class="mb-0 fw-700 fs-10pt f-raleway">Digital</h5>
            <p class="fw-300 fs-10pt f-raleway">September 05, 2021</p>
          </div>
          <div class="absolute right-0 bottom-0 me-4 mb-1">
            <p class="fs-8pt text-muted">{{$moment().add(10,"days").fromNow()}}</p>
          </div>
        </div>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import dummy from '@/components/dummy/dummy.vue'
import { faker } from '@/components/dummy/faker.js'

export default {
  name: "cardEvento",
  components: {},

  data(){
    return {
    }
  },

  methods:{

  }
}
</script>

<style scoped>

.fs-7 {
  font-size: 8pt !important;
}

</style>
