<template>
  <div class="">
    <v-card class="mb-4 pb-3" color="">
      <v-container>
        <v-row no-gutters align="center" justify="start">
          <v-col cols="auto">
            <v-icon class="ms-0 me-0 material-icons-two-tone fs-20pt">info</v-icon>
          </v-col>
          <v-col class="ms-2 fw-300 text-left fw-600 fs-14pt f-lato">
            Confirmação dos seus dados pessoais
          </v-col>
        </v-row>
      </v-container>
      <v-divider class="my-0"></v-divider>
      <v-card class="mx-4 my-3 py-2">
        <p class="mb-1 mt-0 pt-0 mx-4 line-height-2 text-justify fw-400 fs-10pt f-lato">
          Nome completo:
        </p>
        <p class="mt-0 mb-0 mx-4 line-height-2 text-justify fw-500 fs-11pt f-roboto">
          {{userLog.nome}}
        </p>
      </v-card>
      <v-card class="mx-4 my-3 py-2">
        <p class="mb-1 mt-0 pt-0 mx-4 line-height-2 text-justify fw-400 fs-10pt f-lato">
          Email:
        </p>
        <p class="mt-0 mb-0 mx-4 line-height-2 text-justify fw-500 fs-11pt f-roboto">
          <v-icon class="ms-0 me-0 material-icons-two-tone fs-12pt">email</v-icon>
          {{userLog.email}}
        </p>
        <p v-if="email != userLog.email" v-for="email in userLog.emails" :key="email" class="mt-0 mb-0 mx-4 line-height-2 text-justify fw-500 fs-11pt f-roboto">
          <v-icon class="ms-0 me-0 material-icons-two-tone fs-12pt">email</v-icon>
          {{email}}
        </p>
      </v-card>
      <v-card class="mx-4 my-3 py-2">
        <p class="mb-1 mt-0 pt-0 mx-4 line-height-2 text-justify fw-400 fs-10pt f-lato">
          Telefone:
        </p>
        <p class="mt-0 mb-0 mx-4 line-height-2 text-justify fw-500 fs-11pt f-roboto">
          <v-icon class="ms-0 me-0 material-icons-two-tone fs-12pt">smartphone</v-icon>
          {{userLog.telefone}}
        </p>
      </v-card>
      <p class="mb-1 mt-4 text-center pt-0 mx-4 line-height-2 fw-500 fs-12pt f-lato">
        <v-form ref="confirmaref">
          <v-checkbox @click="confirmar" class="" v-model="confirmacao" dense color="primary" :rules="[rules.confirma]">
            <template v-slot:label>
              <div class="fs-10pt">Confirmo que meus dados estão corretos.</div>
            </template>
          </v-checkbox>
        </v-form>
      </p>
    </v-card>
    <v-btn class="ms-4 px-2 left" outlined small :color="'#8b70a1'" @click="$emit('anterior')">
      <v-icon class="ms-0 me-1 material-icons-two-tone">
        undo
      </v-icon>
      anterior
    </v-btn>
    <v-btn class="ms-4 px-2 right" outlined small :color="'#380f5c'" @click="proximo">
      Próximo
      <v-icon class="ms-0 ms-1 material-icons-two-tone">
        forward
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';

export default {
  name: "step01",
  components: {},
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      confirmacao: false,
      rules: {
        confirma: value => !!value || 'Confirme os dados',
        required: value => !!value || 'Obrigatório',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      inscricao: null,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    rdb.ref('/curriculo/2021S2/inscricoesUCD/'+this.userLog.id).on('value',function(snapshot) {
      self.inscricao = snapshot.val();
      if(self.inscricao != null) {
        self.confirmacao = self.inscricao.confirmacao;
      }
      console.log("self.inscricao",self.inscricao);
      self.build();
    });
  },
  methods:{

    build() {
      var self = this;
    },

    confirmar() {
      console.log(this.confirmacao);
      rdb.ref("/curriculo/2021S2/inscricoesUCD/"+this.userLog.id+"/confirmacao").set(this.confirmacao);
    },

    proximo() {
      if(!this.confirmacao) {
        this.$refs.confirmaref.validate()
        this.toastD("Confirme que seus dados estejam corretos!");
        return;
      }
      this.$emit("proximo");
    }
  }
}
</script>

<style scoped>
</style>
