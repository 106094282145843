
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import { rdbDummy01 } from '@/firebasedb-dummy01.js';

var dbclone = "dbclone01";
var routes = [ "root", "users", "areas", "campus", "cursos", "equipes", "ies", "pessoas", "ucs" ];
let dbmodelRoutes = {};

function buildRoute(route) {
  return {
    on(key = "", cbfunc) {
      //console.log("on "+"/"+dbclone+"/"+route+"/"+key);
      //console.log(rdbDummy01);
      rdbDummy01.ref("/"+dbclone+"/"+route+"/"+key).on("value", function(snapshot) {
        cbfunc(snapshot.val());
      });
    },
    set(key = "", data, cbfunc) {
      if(key == undefined || key == "") {
        console.log("Erro "+route+": Chave inválida");
        return;
      }
      rdbDummy01.ref("/"+dbclone+"/"+route+"/"+key).set(data).then(cbfunc);
    },
  }
}

for(var idx in routes) {
    var route = routes[idx];
    dbmodelRoutes[routes[idx]] = buildRoute(routes[idx]);
}

export const dbmodel = dbmodelRoutes;
