<template>
  <div class="">
    <v-card class="mx-auto pb-1">
      <v-img class="white--text align-end" height="230px" src="https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Fbanner-4x1.png?alt=media&token=2d118916-a69e-4310-9f33-82d8eb13d55d">
        <div class="py-0 mt-3 mx-2">
          <p class="m-0 fs-11pt fw-500 f-poppins line-height-3">
            <span class="fw-700 fs-15pt">RECRUTAMENTO E SELEÇÃO</span> <br>
            interna de professores para a docência em <br>
            Unidades Curriculares Digitais <span class="fw-700 fs-14pt">(UCD)</span>
          </p>
          <v-divider class="my-1"></v-divider>
          <p class="m-0 fs-13pt fw-700 f-lato">
            inscrições até 11/11/2021
          </p>
        </div>
        <div class="mb-1 mt-0 right">
          <v-btn class="me-2" color="white" outlined x-small href="https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/editais%2FEDITAL%20DOCENCIA%20EM%20UC%20DIGITAL_2022-1_LIVE.pdf?alt=media&token=5730da5a-bbc2-403c-b2a2-426892778ccf" target="_blank">
            ver edital
          </v-btn>
        </div>
      </v-img>
      <p class="my-2 mx-12 line-height-3 fw-300 text-center fs-14pt f-lato">
        Formulário de inscrição para seleção docente para a atuação com as <strong class="fs-14pt f-poppins fw-600">UCDs em 2022-1</strong>
      </p>
      <p class="my-2 mx-2 line-height-3 text-center fs-12pt f-poppins">

      </p>
      <div class="text-center my-2">
        <v-btn class="px-2 text-white" small elevation="2" :color="'#380f5c'" @click="$refs.inscricaoucdref.show()">
          <v-icon class="ms-0 me-1 fs-14pt material-icons-two-tone cor-i-white">
            subscriptions
          </v-icon>
          Inscrever
        </v-btn>
      </div>
      <v-divider class="my-0"></v-divider>
      <p class="my-3 mx-2 line-height-3 text-justify fs-9pt f-raleway">
        CONFORME EDITAL DE ABERTURA DE INSCRIÇÕES PARA RECRUTAMENTO E SELEÇÃO INTERNA DE PROFESSORES PARA A DOCÊNCIA EM UNIDADES CURRICULARES DIGITAIS (UCD) PUBLICADO EM
        <strong>09/11/2021</strong>.
      </p>
    </v-card>
    <inscricaoucd ref="inscricaoucdref" />
  </div>
</template>

<script>
  import { rdb, snapshotValToArray } from '@/firebasedb.js';
  import inscricaoucd from "@/views/live/inscricaoUCD/inscricao-ucd.vue"

  export default {
    name: "banner-inscricao",
    components: { inscricaoucd },
    props: [],
    computed: {},
    data(){
      return {
        loading: false,
        refresh: 0,
      }
    },
    watch: {},
    mounted: function() {
      var self = this;
      //self.loading = true;
      //rdb.ref('').on('value',function(snapshot) {
      //  self.algo = snapshot.val();
      //  console.log("self.algo",self.algo);
      //  self.build();
      //});
    },
    methods:{

      build() {
          var self = this;
      }
    }
  }
</script>

<style scoped>
</style>
