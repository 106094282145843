
import axios from 'axios';
import oauth from 'axios-oauth-client';

export const apiAlunos = {

  auth(cbfunc) {
    const getAuthorizationCode = oauth.client(axios.create(), {
      url: 'https://identity-hmlaca.animaeducacao.com.br/core/connect/token',
      grant_type: 'client_credentials',
      client_id: 'vpaforms',
      client_secret: '[<3u=CMJX?vY\\R}j;q84',
      redirect_uri: '',
      code: '',
      scope: 'apialuno',
    });
    const auth = getAuthorizationCode().then(function(auth){
      //console.log("auth",auth);
      cbfunc(auth,false);
    }, function(err) {
      //console.log("erro");
      cbfunc({},err);
    });
  },

  callApi(route,raFilter,limitTo, cbfunc) {
    apiAlunos.auth(function(auth,err) {
      if(!err) {
        axios.get('https://master-aca-apialuno.anima.qa/api/v1/alunos/'+route, {
          headers: {"Authorization" : 'Bearer '+auth.access_token },
          params: {
            "options.filters.numeroMatriculaContem": raFilter,
            "options.pagination.skip": 0,
            "options.pagination.take": limitTo,
          }
        }).then(function(response) {
          var response = response.data;
          //console.log(response);
          cbfunc(response,false);
        }, function(err) {
          console.log("erro",err);
          cbfunc({},err);
        });
      } else {
        cbfunc({},err);
      }
    });
  },

  byRA(text,cbfunc) {
    apiAlunos.callApi("dados_academicos", text, 10, function(data,err) {
      if(!err) {
        cbfunc(data,false);
      } else {
        cbfunc({},err);
      }
    });
  },

  detailsByRA(text,cbfunc) {
    apiAlunos.callApi("dados_cadastrais", text, 10, function(data,err) {
      if(!err) {
        cbfunc(data,false);
      } else {
        cbfunc({},err);
      }
    });
  },

  periodosLetivosByCod(codigo,cbfunc) {
    apiAlunos.callApi("/"+codigo+"/periodos_letivos", "", 10, function(data,err) {
      if(!err) {
        cbfunc(data,false);
      } else {
        cbfunc({},err);
      }
    });
  },

  matriculasByCod_Periodo(codigo, periodo, cbfunc) {
    apiAlunos.callApi("/"+codigo+"/periodos_letivos/"+periodo+"/matriculas", "", 10, function(data,err) {
      if(!err) {
        cbfunc(data,false);
      } else {
        cbfunc({},err);
      }
    });
  },

}
