<template>
  <v-dialog v-model="show" persistent max-width="290">
    <v-card color="rgba(255, 255, 255, 0.7)" loading>
      <div class="text-center mb-4" style="margin-top: 20px;">
        <img width="180px" class="mt-n1" src="https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Flogos%2Flogo-800.png?alt=media&token=ff063aea-169b-4c6b-86ec-bc872bffeab1" alt="">
      </div>
      <v-card-text>
        <v-progress-linear v-model="value" color="blue-grey" height="25">
          <template v-slot:default="{ value }">
            <strong>{{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>
        <p class="my-0 py-0 mt-1 text-center fs-10pt f-sanspro">
          carregando...
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'apploading',
  components: {},
  props: [ "show", "value" ],
  data(){
    return {
    }
  },
  watch: {},
  computed: {},
  created() {

  },
  mounted() {

  },
  methods: {

  }
}

</script>
