<template>
  <div class="">
    <v-card class="mb-4 pb-3" color="">
      <p class="my-2 pt-3 mx-2 line-height-3 fw-300 text-center fs-14pt f-lato">
        Formulário de inscrição para seleção docente para a atuação com as
      </p>
      <p class="my-2 mx-2 line-height-3 text-center fs-12pt f-poppins">
        <strong class="fw-600">UCDs em 2022-1</strong>
      </p>
      <v-divider class="my-0"></v-divider>
      <p class="my-2 mx-4 line-height-4 text-justify fs-10pt f-raleway">
        CONFORME EDITAL DE ABERTURA DE INSCRIÇÕES PARA RECRUTAMENTO E SELEÇÃO INTERNA DE PROFESSORES PARA A DOCÊNCIA EM UNIDADES CURRICULARES DIGITAIS (UCD) PUBLICADO EM
        <strong>09/11/2021</strong>.
      </p>
      <div class="text-center">
        <v-btn class="" outlined color="teal" text x-small href="https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/editais%2FEDITAL%20DOCENCIA%20EM%20UC%20DIGITAL_2022-1_LIVE.pdf?alt=media&token=5730da5a-bbc2-403c-b2a2-426892778ccf" target="_blank">
          ver edital
        </v-btn>
      </div>
    </v-card>
    <v-btn disabled class="ms-4 px-2 right" outlined small :color="'#380f5c'" @click="proximo">
      Próximo
      <v-icon class="ms-0 ms-1 material-icons-two-tone">
        forward
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
  import { rdb, snapshotValToArray } from '@/firebasedb.js';

  export default {
    name: "step01",
    components: {},
    props: [],
    computed: {},
    data(){
      return {
        loading: false,
        refresh: 0,
      }
    },
    watch: {},
    mounted: function() {
      var self = this;
      //self.loading = true;
    },
    methods:{

      build() {
          var self = this;
      },

      proximo() {
        this.$emit("proximo");
      }
    }
  }
</script>

<style scoped>
</style>
