<template>
  <div class="">
    <v-form ref="passo5ref">
      <v-card class="mb-4 pb-3 px-3 pt-2 card-scroll" height="410px" color="" >
        <p class="fs-10pt fw-500 mt-0 mb-1">
          <v-icon class="ms-0 me-0 material-icons-two-tone fs-16pt">help</v-icon>
          Descreva, em até 10 (dez) linhas, a sua experiência como docente em unidade curricular (UC) dos currículos integrados.
        </p>
        <v-textarea @change="update" class="fs-11pt" :rules="[rules.required]" v-model="experienciaE2A" solo label="Descreva sua experiência" dense></v-textarea>
        <p class="fs-10pt fw-500 mt-0 mb-1">
          <v-icon class="ms-0 me-0 material-icons-two-tone fs-16pt">help</v-icon>
          Escreva, em até 10 (dez) linhas o que diferencia a docência no ensino presencial e no ensino digital.
        </p>
        <v-textarea @change="update" class="fs-11pt" :rules="[rules.required]" v-model="resposta01" solo label="Resposta" dense></v-textarea>
        <p class="fs-10pt fw-500 mt-0 mb-1">
          <v-icon class="ms-0 me-0 material-icons-two-tone fs-16pt">help</v-icon>
          Compartilhe em até 10 (dez) linhas um relato de prática bem sucedido em sala de aula síncrona (zoom) ocorrida no período de pandemia (covid-19).
        </p>
        <v-textarea @change="update" class="fs-11pt" :rules="[rules.required]" v-model="resposta02" solo label="Resposta" dense></v-textarea>
      </v-card>
    </v-form>
    <v-btn class="ms-4 px-2 left" outlined small :color="'#8b70a1'" @click="$emit('anterior')">
      <v-icon class="ms-0 me-1 material-icons-two-tone">
        undo
      </v-icon>
      anterior
    </v-btn>
    <v-btn class="ms-4 px-2 right" outlined small :color="'#380f5c'" @click="proximo">
      Próximo
      <v-icon class="ms-0 ms-1 material-icons-two-tone">
        forward
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import tagarea from "./tagArea.vue";

export default {
  name: "step01",
  components: { tagarea },
  props: [],
  computed: {},
  data(){
    return {
      tab: null,
      loading: false,
      refresh: 0,
      experienciaE2A: "",
      resposta01: "",
      resposta02: "",
      rules: {
        required: value => !!value || 'Obrigatório',
      },
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    rdb.ref('/curriculo/2021S2/inscricoesUCD/'+this.userLog.id).on('value',function(snapshot) {
      self.inscricao = snapshot.val();
      if(self.inscricao == null) {
        self.experienciaE2A = "";
        self.resposta01 = "";
        self.resposta02 = "";
      } else {
        self.experienciaE2A = self.inscricao.experienciaE2A;
        self.resposta01 = self.inscricao.resposta01;
        self.resposta02 = self.inscricao.resposta02;
      }
      console.log("self.inscricao",self.inscricao);
      self.build();
    });

  },
  methods:{

    build() {
      var self = this;
    },

    proximo() {
      if(this.experienciaE2A.trim() == "") {
        this.toastD("Dados incompletos!");
        this.$refs.passo5ref.validate();
        return;
      }
      if(this.resposta01.trim() == "") {
        this.toastD("Dados incompletos!");
        this.$refs.passo5ref.validate();
        return;
      }
      if(this.resposta02.trim() == "") {
        this.toastD("Dados incompletos!");
        this.$refs.passo5ref.validate();
        return;
      }
      this.$emit("proximo");
    },

    remove (item) {
      console.log("item",item);
      console.log("this.areasSel",this.areasSel);
      let pos = -1;
      for(var key in this.areasSel) {
        console.log("this.areasSel[key]",this.areasSel[key]);
        console.log("item.id",item.id);
        if(this.areasSel[key] == item.id) {
          pos = key;
          break;
        }
      }
      if (pos >= 0) {
        this.areasSel.splice(pos, 1);
      }
      console.log("this.areasSel",this.areasSel);
    },

    update() {
      console.log("experienciaE2A",this.experienciaE2A);
      console.log("resposta01",this.resposta01);
      console.log("resposta02",this.resposta02);
      rdb.ref("/curriculo/2021S2/inscricoesUCD/"+this.userLog.id+"/experienciaE2A").set(this.experienciaE2A.trim());
      rdb.ref("/curriculo/2021S2/inscricoesUCD/"+this.userLog.id+"/resposta01").set(this.resposta01.trim());
      rdb.ref("/curriculo/2021S2/inscricoesUCD/"+this.userLog.id+"/resposta02").set(this.resposta02.trim());
    }
  }
}
</script>

<style scoped>
</style>
