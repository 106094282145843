<template>
  <v-card :color="cor.active.B" v-c:K v-if="relatoObj != null">
    <v-card-title class="text-left mt-0 pt-2 mb-0 pb-0">
      <v-avatar size="40" class="mt-n3">
        <img v-if="autor != null" :src="autor.urlFoto" alt="">
      </v-avatar>
      <div class="ms-1" v-if="autores.length > 0">
        <p class="ms-1 mt-1 mb-0 py-0 text-nonecase f-sanspro fw-500 fs-12pt line-height-2">{{autores[0].nome}}</p>
        <p class="ms-1 mt-0 mb-0 py-0 text-nonecase f-sanspro fw-300 fs-10pt line-height-3">{{autores[0].cargo}} - {{relatoObj.ies.marca}} - {{autores[0].regional}}</p>
        <p class="ms-1 my-0 py-0 fw-300 fs-10pt line-height-3">
          <i v-i:duo#clock#12 class="left me-1 mt-n1" v-c:I></i>
          <p class="left mt-0 fw-300 fs-8pt line-height-3" v-c:I>{{$moment(relatoObj.createdAt).fromNow()}}</p>
        </p>
      </div>
      <v-spacer></v-spacer>
      <v-btn icon class="right mt-n4 pt-0">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="mt-n1 pt-0 text-left text-justify fs-11pt line-height-3 f-raleway fw-300" v-c:K>
      <readmore class="mt-0 pt-0 pb-0" size="200" :text="relatoObj.post"/>
      <div class="mt-4 pt-0" v-if="relatoObj.videoUrl != ''">
        <video width="100%" height="240px" controls disablePictureInPicture controlsList="nodownload" :poster="relatoObj.capaUrl" :src="relatoObj.videoUrl"></video>
      </div>
      <div class="mt-2 pt-0" v-if="relatoObj.videoUrl == ''">
        <v-card outlined>
          <img style="width: 100%" :src="relatoObj.capaUrl" alt="">
        </v-card>
      </div>
      <v-row class="mt-1">
        <v-col cols="4" v-for="(foto,index) in relatoObj.fotos" :key="index">
          <v-card outlined class="p-1">
            <img style="width: 100%" :src="foto.urlFoto" alt="">
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-1 pt-0">
        <v-col cols="12" class="mt-0 pt-0">
          <div class="right my-0 py-0">
            <v-btn color="" class="px-1 fs-9pt f-sanspro text-nonecase" text x-small v-for="(doc,index) in relatoObj.documentos" :key="index" :href="doc.urlDoc" target="_blank">
              <i v-i:ic#insert-drive-file#16 class="btn-icon-left"></i>
              <span class="ms-0">Relato</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <div class="mt-2">
        <feedcontroler ref="fcontroler" :rdbPath="pathControler" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { rdb } from '@/firebasedb.js'
import moment from 'moment';
import readmore from '@/components/readmore.vue';
import feedcontroler from '@/views/feedsComponent/feedControler.vue'

export default {
  name: 'postrelato',
  components: { readmore, feedcontroler },
  props: [ "relato" ],
  data(){
    return {
      pathControler: "/curriculo/relatos/notloaded/",
      area: { cor: "#303030" },
      relatoObj: null,
      autor: null,
      autores: [],
    }
  },
  created() {
    this.log_s('Created');
  },
  mounted() {
    var self = this;
    this.log_s('Mounted');
    this.loadRelato();
  },
  methods: {

    loadRelato() {
      var self = this;
      //console.log("self.relato",self.relato);
      self.pathControler = "/curriculo/socialData/relatos/"+self.relato.id+"/";
      self.relatoObj = self.relato;
      self.autores = [];
      rdb.ref('/curriculo/areas/'+self.relato.areaID).on('value', function(snapshot) {
        self.relato.area = snapshot.val();
        rdb.ref('/curriculo/ies/'+self.relato.iesID).on('value', function(snapshot) {
          self.relato.ies = snapshot.val();
          //console.log("self.autor",self.autor);
          var index = 0;
          for(var key in self.relato.autores) {
            rdb.ref('/usuarios/pessoas/'+key).on('value', function(snapshot) {
              if(index == 0) {
                self.autor = snapshot.val();
              }
              index++;
              self.autores.push(snapshot.val());
            });
          }
        });
      });
    },

  }
}
</script>

<style scoped>

video::-internal-media-controls-download-button {
  display:none;
}

video::-webkit-media-controls-enclosure {
  overflow:hidden;
}

video::-webkit-media-controls-panel {
  width: calc(100% + 30px); /* Adjust as needed */
}

</style>
