<template>
  <span>
    <v-btn v-if="!selecionado" @click="selecionaUC(true)" class="text-nonecase px-1" color="primary" text x-small>
      <v-icon class="fs-14pt me-1">check_box_outline_blank</v-icon>
      Selecionar
    </v-btn>
    <v-btn v-if="selecionado" @click="selecionaUC(false)" class="text-nonecase px-1" color="primary" text x-small>
      <v-icon class="fs-14pt me-1">check_box</v-icon>
      não selecionar
    </v-btn>
  </span>
</template>

<script>
  import { rdb, snapshotValToArray } from '@/firebasedb.js';

  export default {
    name: "",
    components: {},
    props: {
      uc: { default: null, type: Object },
      field: { default: "ucs20221Sel", type: String },
    },
    computed: {},
    data(){
      return {
        loading: false,
        refresh: 0,
        selecionado: false,
      }
    },
    watch: {},
    mounted: function() {
      var self = this;
      //self.loading = true;
      rdb.ref("/curriculo/2021S2/inscricoesUCD/"+this.userLog.id+"/"+this.field+"/"+this.uc.id).on('value',function(snapshot) {
        self.selecionado = snapshot.val();
        //console.log("self.selecionado",self.selecionado);
        //self.build();
      });
    },
    methods:{

      build() {
          var self = this;
      },

      selecionaUC(value) {
        console.log(this.uc);
        if(value) {
          rdb.ref("/curriculo/2021S2/inscricoesUCD/"+this.userLog.id+"/"+this.field+"/"+this.uc.id).set(value);
        } else {
          rdb.ref("/curriculo/2021S2/inscricoesUCD/"+this.userLog.id+"/"+this.field+"/"+this.uc.id).set(null);
        }
      }
    }
  }
</script>

<style scoped>
</style>
