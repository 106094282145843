<template>
  <v-row>
    <v-col cols="12" lg="7">
      <v-row>
        <v-col cols="12" class="m-0 p-0 mb-1">
          <appintegraplugin pluginName="recursoA2/card-recurso" :key="1"/>
        </v-col>
        <v-col cols="12" class="mb-6">
          <bannerinscricao />
        </v-col>
        <v-col cols="12" v-for="(item,index) in relatos" :key="index">
          <postrelato :relato="item"/>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" lg="5">
      <sobre />
      <datasproximas />
      <cardevento />
      <cardevento />
      <cardevento />
      <cardevento />
    </v-col>

    <atualizarusuario ref="atualizarusuarioref" />
  </v-row>
</template>

<script>
// @ is an alias to /src
import { rdb, snapshotValToArray, generatePushID } from '@/firebasedb.js'
import { notification } from '@/components/notification/notification.js'
import dummy from '@/components/dummy/dummy.vue'
import { faker } from '@/components/dummy/faker.js'
import sobre from './sobre.vue'
import postrelato from '@/views/relatos/postRelato.vue'
import datasproximas from './datasProximas.vue'
import cardevento from "@/components/duaiscomponents/cardEvento.vue"
import atualizarusuario from "./atualizar-usuario.vue";
import bannerinscricao from "@/views/live/inscricaoUCD/banner-inscricao.vue"
import appintegraplugin from "@/components/plugins2/app-integra-plugin.vue";

export default {
  name: 'Home',
  components: { appintegraplugin, dummy, sobre, postrelato, datasproximas, cardevento, atualizarusuario, bannerinscricao },
  computed: {
  },
  data () {
    return {
      relatos: [],
    }
  },
  created() {
    this.log_s('Created');
  },
  mounted() {
    var self = this;
    this.log_s('Mounted');
    rdb.ref('/curriculo/relatos').on('value', function(snapshot) {
      self.relatos = self.shuffleArray(snapshotValToArray(snapshot.val()));
      //console.log("self.relatos",self.relatos);
    });

    if(this.userLog.email.trim() == "") {
      this.$refs.atualizarusuarioref.show("email");
    }
    if(this.userLog.cpf == undefined || this.userLog.cpf.trim() == "") {
      this.$refs.atualizarusuarioref.show("cpf");
    }

  },
  methods: {

    shuffleArray(arr) {
      // Loop em todos os elementos
      for (let i = arr.length - 1; i > 0; i--) {
        // Escolhendo elemento aleatório
        const j = Math.floor(Math.random() * (i + 1));
        // Reposicionando elemento
        [arr[i], arr[j]] = [arr[j], arr[i]];
      }
      // Retornando array com aleatoriedade
      return arr;
    }

  }
}
</script>
