<template>
  <span>
    <span class="me-0" v-if="!readMore">{{texto.slice(0, sizeSel)}}... </span>
    <button class="pt-0 ps-0 mt-0 ms-0 btn btn-link btn-sm fs-8pt f-titillium fw-400 text-dark" v-if="!readMore" @click="clickReadMore">Ler mais</button>
    <span v-if="readMore">
      {{texto}}
      <button class="pt-0 ps-0 mt-0 ms-0 btn btn-link btn-sm fs-8pt f-titillium fw-400 text-dark" v-if="readMore && texto.length > sizeSel" @click="clickReadMore2">Ler menos</button>
    </span>
  </span>
</template>

<script>

export default {
  name: 'readmore',
  components: { },
  props: [ 'text', 'size' ],
  computed: {},
  data() {
    return {
      readMore: false,
      texto: "",
      sizeSel: 200,
    }
  },
  watch: {
    text(to, from) {
      var self = this;
      //console.log("readmore change slot");
      //console.log(this.text);
      this.texto = "";
      if(this.texto != undefined) {
        this.texto = this.text;
      }
      if(this.text.length > this.size) {
        this.readMore = false;
      } else {
        this.readMore = true;
      }
    },
    size(to, from) {
      this.sizeSel = this.size;
    }
  },
  mounted() {
    //console.log("readmore Mounted");
    //console.log(this.text);
    this.sizeSel = this.size;
    this.texto = this.text;
    if(this.text.length > this.sizeSel) {
      this.readMore = false;
    } else {
      this.readMore = true;
    }
  },
  methods: {

    clickReadMore() {
      this.readMore = !this.readMore;
    },
    clickReadMore2() {
      this.readMore = !this.readMore;
    }

  }
}
</script>
