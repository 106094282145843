import firebase from 'firebase/app';
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/storage'
import "firebase/auth"

const secondaryApp = firebase.initializeApp({
  apiKey: "AIzaSyCa7b4PO7O0ia4wdQ7J0hjO1imVjkDhuEk",
  authDomain: "appintegradummy01.firebaseapp.com",
  databaseURL: "https://appintegradummy01-default-rtdb.firebaseio.com",
  projectId: "appintegradummy01",
  storageBucket: "appintegradummy01.appspot.com",
  messagingSenderId: "705946937404",
  appId: "1:705946937404:web:503266a6d67541790a7b39"
},"secondary")

export const rdbDummy01 = secondaryApp.database()
export const ServerValueDummy01 = secondaryApp.database.ServerValue
export const fbstDummy01 = secondaryApp.storage();

if(location.hostname === "localhost") {
  // Point to the RTDB emulator running on localhost.
  //rdb.useEmulator("localhost", 9000);
}
//window.firebase = firebase;

function initAuth() {
  firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
      // User is signed in.
      //console.log("user auth ok");
      user.getIdToken(/* forceRefresh */ true).then(function(idToken) {
        // Send token to your backend via HTTPS
        // ...
        //console.log("idToken",idToken.substr(0,30));
        //console.log("idToken",idToken);
      }).catch(function(error) {
        // Handle error
        //console.log("error",error);
      });
    } else {
      // No user is signed in.
    }
  });

  firebase.auth().signInWithEmailAndPassword("appintegra.lab@gmail.com", "miroma")
  .then((userCredential) => {
    // Signed in
    var user = userCredential.user;
    //console.log("userCredential.user ok",userCredential.user);
    //console.log("userCredential.user ok");
    // ...
  })
  .catch((error) => {
    var errorCode = error.code;
    var errorMessage = error.message;
    //console.log("erro",error);
  });
}

initAuth();
