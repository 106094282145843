<template>
  <div class="">
    <v-form ref="passo3ref">
      <v-card class="mb-4 pb-3 card-scroll" height="410px" color="" >
        <v-container>
          <v-row no-gutters align="center" justify="start">
            <v-col cols="auto">
              <v-icon class="ms-0 me-0 material-icons-two-tone fs-16pt">link</v-icon>
            </v-col>
            <v-col class="ms-2 fw-300 text-left fw-400 fs-12pt">
              Compartilhe o link de acesso ao seu currículo lattes
            </v-col>
          </v-row>
          <div class="mt-2 mb-0">
            <v-text-field @change="lattesSel" class="" v-model="lattes" dense solo :rules="[rules.required]">
              <template v-slot:label>
                <div class="fs-10pt">URL(link) do currículo lattes</div>
              </template>
            </v-text-field>
          </div>
          <v-divider class="my-2"></v-divider>
          <v-row no-gutters align="center" justify="start">
            <v-col cols="auto">
              <v-icon class="ms-0 me-0 material-icons-two-tone fs-16pt">business</v-icon>
            </v-col>
            <v-col class="ms-2 fw-300 text-left fw-400 fs-12pt">
              Selecione a sua Escola (Marca)
            </v-col>
          </v-row>
          <v-radio-group :rules="[rules.required]" hide-details v-model="marca" dense row class="py-0 mb-0">
            <v-radio value="AGES" @click="marcaSel">
              <template v-slot:label>
                <div class="fs-10pt">AGES</div>
              </template>
            </v-radio>
            <v-radio value="USJT" @click="marcaSel">
              <template v-slot:label>
                <div class="fs-10pt">USJT (São Judas)</div>
              </template>
            </v-radio>
            <v-radio value="UNA" @click="marcaSel">
              <template v-slot:label>
                <div class="fs-10pt">UNA</div>
              </template>
            </v-radio>
            <v-radio value="UNISOCIESC" @click="marcaSel">
              <template v-slot:label>
                <div class="fs-10pt">UNISOCIESC</div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-container>
        <v-divider class="my-0"></v-divider>
        <p class="px-2 mx-2 my-2">
          Quanto tempo atua como docente na IES anteriormente assinalada?
        </p>
        <v-container>
          <v-row align="center" justify="start">
            <v-col cols="12" md="6">
              <v-text-field class="" v-model="tempo" dense solo :rules="[rules.numberRule,rules.required]">
                <template v-slot:label>
                  <div class="fs-10pt">Tempo</div>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="auto" class="">
              <v-radio-group v-model="tempoMesAno" dense row class="py-0 mb-0">
                <v-radio label="meses" value="meses" @click="mesanoSel">
                  <template v-slot:label>
                    <div class="fs-10pt">meses</div>
                  </template>
                </v-radio>
                <v-radio label="anos" value="anos" @click="mesanoSel">
                  <template v-slot:label>
                    <div class="fs-10pt">anos</div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
        <v-divider class="my-0"></v-divider>
        <p class="px-2 mx-2 mt-3 mb-0">
          Tempo de experiência na docência no ensino superior
        </p>
        <v-radio-group hide-details v-model="tempoDocencia" dense row class="py-0 mt-1 mb-3 ms-2" :rules="[rules.required]">
          <v-radio @click="tempoDocenciaSel" :value="item" v-for="item in ['Menos de dois anos','Entre dois e quatro anos','Entre quatro e sete anos', 'Entre sete e nove anos','Dez anos ou mais' ]" :key="item">
            <template v-slot:label>
              <div class="fs-10pt">{{item}}</div>
            </template>
          </v-radio>
        </v-radio-group>
        <v-divider class="my-0"></v-divider>
        <p class="px-2 mx-2 mt-3 mb-0">
          Qual a sua titulação máxima?
        </p>
        <v-radio-group hide-details v-model="titulacao" dense row class="py-0 mt-1 mb-2 ms-2" :rules="[rules.required]">
          <v-radio @click="titulacaoSel" :value="item" v-for="item in ['Aperfeiçoamento (180h)','Especialização (360h)','Mestrado', 'Doutorado','Pós-doutorado' ]" :key="item">
            <template v-slot:label>
              <div class="fs-10pt">{{item}}</div>
            </template>
          </v-radio>
        </v-radio-group>
        <v-divider class="my-0"></v-divider>
        <p class="px-2 mx-2 mt-3 mb-0">
          Você possui publicações científicas nos últimos cinco anos (livro, capitulo de livro, artigo)
        </p>
        <v-radio-group hide-details v-model="publicacoes" dense row class="py-0 mt-1 mb-2 ms-2" :rules="[rules.required]">
          <v-radio @click="publicacoesSel" :value="item" v-for="item in ['Não possuo publicações','Sim, uma publicação','Sim, duas publicações', 'Sim, três publicações', 'Sim, quatro ou mais publicações' ]" :key="item">
            <template v-slot:label>
              <div class="fs-10pt">{{item}}</div>
            </template>
          </v-radio>
        </v-radio-group>
        <v-divider class="my-0"></v-divider>
        <p class="px-2 mx-2 mt-3 mb-0">
          Você já atuou como docente em unidade curricular (UC) dos currículos integrados?
        </p>
        <v-radio-group hide-details v-model="atuouUC" dense row class="py-0 mt-1 mb-2 ms-2" :rules="[rules.required]">
          <v-radio @click="atuouUCSel" :value="item" v-for="item in ['Sim, apenas em UC presencial','Sim, apenas em UC nos cursos LIVE', 'Sim, em UC nos cursos presencial e LIVE', 'Não atuei como docente em UC' ]" :key="item">
            <template v-slot:label>
              <div class="fs-10pt">{{item}}</div>
            </template>
          </v-radio>
        </v-radio-group>
        <v-divider class="my-0"></v-divider>
        <p class="px-2 mx-2 mt-3 mb-0">
          Qual a sua área de atuação?
        </p>
        <v-autocomplete class="mx-4 my-0 py-0" dense solo v-model="areasSel" :items="areas" chips color="blue-grey lighten-2" label="Áreas" item-text="nome" item-value="id" multiple :rules="[rules.required]">
          <template v-slot:selection="data">
            <v-chip class="my-1 py-0" v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="remove(data.item)">
              <tagarea :sigla="data.item.sigla" />
              <span class="ms-1 fw-300 f-raleway fs-8pt">{{ data.item.nome.substring(0,14) }}</span>
            </v-chip>
          </template>
          <template v-slot:item="data">
            <div class="my-0 py-0" style="max-height: 10px;">
              <tagarea :sigla="data.item.sigla" />
              <span class="fw-300 f-raleway fs-10pt ms-1 mt-1">
                {{data.item.nome}}
              </span>
            </div>
          </template>
        </v-autocomplete>
        <v-divider class="my-0"></v-divider>
        <p class="px-2 mx-2 mt-3 mb-0">
          Quais destas experiências você possui com educação a distância?
        </p>
        <div class="mx-4">
          <v-checkbox @click="experienciasEADSel" :label="item" v-for="item in experiencia" v-model="experienciasEAD" dense color="primary" :value="item" hide-details :key="item">
            <template v-slot:label>
              <div class="fs-10pt">{{item}}</div>
            </template>
          </v-checkbox>
        </div>
        <div class="mx-4 mt-2" v-if="experienciasEADOutro">
          <p class="fs-10pt fw-500 mt-0 mb-1">
            <v-icon class="ms-0 me-0 material-icons-two-tone fs-16pt">help</v-icon>
            Descreva outras experiências
          </p>
          <v-textarea @change="outrasExperienciasEADChange" class="fs-11pt" v-model="outrasExperienciasEAD" rows="2" solo label="Descreva sua experiência" dense></v-textarea>
        </div>
      </v-card>
      <v-btn class="ms-4 px-2 left" outlined small :color="'#8b70a1'" @click="$emit('anterior')">
        <v-icon class="ms-0 me-1 material-icons-two-tone">
          undo
        </v-icon>
        anterior
      </v-btn>
      <v-btn class="ms-4 px-2 right" outlined small :color="'#380f5c'" @click="proximo">
        Próximo
        <v-icon class="ms-0 ms-1 material-icons-two-tone">
          forward
        </v-icon>
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import tagarea from "./tagArea.vue";

export default {
  name: "step01",
  components: { tagarea },
  props: [],
  computed: {
    experienciasEADOutro() {
      return this.experienciasEAD.lastIndexOf("Outro") != -1;
    }
  },
  data(){
    return {
      loading: false,
      refresh: 0,
      lattes: "",
      marca: '',
      tempoMesAno: '',
      tempo: '',
      tempoDocencia: '',
      titulacao: "",
      publicacoes: "",
      atuouUC: "",
      experienciasEAD: [],
      outrasExperienciasEAD: "",
      areasSel: [],
      areas: [],
      rules: {
        required: value => !!value || 'Obrigatório',
        numberRule: v  => {
          if (!v.trim()) return true;
          if (!isNaN(parseFloat(v)) && v >= 0 && v <= 100) return true;
          return 'Apenas números 1 a 100';
        },
      },
      experiencia: [
        "Curadoria e Produção de UCD",
        "Tutoria/Docência em Ambiente Virtual de Aprendizagem em cursos livres",
        "Tutoria/Docência em Ambiente Virtual de Aprendizagem em cursos de aperfeiçoamento",
        "Tutoria/Docência em Ambiente Virtual de Aprendizagem em cursos de pós-graduação",
        "Aluno em Cursos Livres (20h a 180h)",
        "Aluno em cursos de aperfeiçoamento (180h)",
        "Tutoria/Docência em cursos de Formação de professores",
        "Outro"
      ],
      inscricao: null,
    }
  },
  watch: {
    tempo() {
      if (!isNaN(parseFloat(this.tempo)) && this.tempo >= 0 && this.tempo <= 100) {
        console.log(this.tempo);
        rdb.ref("/curriculo/2021S2/inscricoesUCD/"+this.userLog.id+"/tempoIES").set(this.tempo);
      }
    },
    areasSel() {
      console.log("areasSel",this.areasSel);
      rdb.ref("/curriculo/2021S2/inscricoesUCD/"+this.userLog.id+"/areas").set(this.areasSel);
    },
  },
  mounted: function() {
    var self = this;
    //self.loading = true;
    rdb.ref('/curriculo/areas').on('value',function(snapshot) {
      self.areas = snapshotValToArray(snapshot.val());
      console.log("self.areas",self.areas);
      //self.build();
    });
    rdb.ref('/curriculo/2021S2/inscricoesUCD/'+this.userLog.id).on('value',function(snapshot) {
      self.inscricao = snapshot.val();
      if(self.inscricao == null) {
        self.marca = "";
        self.lattes = "";
        self.tempo = "";
        self.tempoMesAno = "";
        self.tempoDocencia = "";
        self.atuouUC = "";
        self.titulacao = "";
        self.publicacoes = "";
        self.outrasExperienciasEAD = "";
        self.experienciasEAD = [];
        self.areasSel = [];
      } else {
        self.marca = self.inscricao.marca;
        self.lattes = self.inscricao.lattes;
        self.tempo = self.inscricao.tempoIES;
        self.tempoMesAno = self.inscricao.tempoMesAno;
        self.tempoDocencia = self.inscricao.tempoDocencia;
        self.atuouUC = self.inscricao.atuouUC;
        self.titulacao = self.inscricao.titulacao;
        self.publicacoes = self.inscricao.publicacoes;
        self.outrasExperienciasEAD = self.inscricao.outrasExperienciasEAD;
        self.experienciasEAD = self.inscricao.experienciasEAD;
        if(self.experienciasEAD == undefined) {
          self.experienciasEAD = [];
        }
        self.areasSel = self.inscricao.areas;
        if(self.areasSel == undefined) {
          self.areasSel = [];
        }
      }
      console.log("self.inscricao",self.inscricao);
      self.build();
    });

  },
  methods:{

    build() {
      var self = this;
    },

    proximo() {
      if(!this.$refs.passo3ref.validate()) {
        this.toastD("Dados incompletos!");
        return;
      }
      this.$emit("proximo");
    },

    remove (item) {
      console.log("item",item);
      console.log("this.areasSel",this.areasSel);
      let pos = -1;
      for(var key in this.areasSel) {
        console.log("this.areasSel[key]",this.areasSel[key]);
        console.log("item.id",item.id);
        if(this.areasSel[key] == item.id) {
          pos = key;
          break;
        }
      }
      if (pos >= 0) {
        this.areasSel.splice(pos, 1);
      }
      console.log("this.areasSel",this.areasSel);
    },

    marcaSel() {
      console.log(this.marca);
      rdb.ref("/curriculo/2021S2/inscricoesUCD/"+this.userLog.id+"/marca").set(this.marca);
    },

    mesanoSel() {
      console.log(this.tempoMesAno);
      rdb.ref("/curriculo/2021S2/inscricoesUCD/"+this.userLog.id+"/tempoMesAno").set(this.tempoMesAno);
    },

    tempoDocenciaSel() {
      console.log(this.tempoDocencia);
      rdb.ref("/curriculo/2021S2/inscricoesUCD/"+this.userLog.id+"/tempoDocencia").set(this.tempoDocencia);
    },

    titulacaoSel() {
      console.log(this.titulacao);
      rdb.ref("/curriculo/2021S2/inscricoesUCD/"+this.userLog.id+"/titulacao").set(this.titulacao);
    },

    publicacoesSel() {
      console.log(this.publicacoes);
      rdb.ref("/curriculo/2021S2/inscricoesUCD/"+this.userLog.id+"/publicacoes").set(this.publicacoes);
    },

    atuouUCSel() {
      console.log(this.atuouUC);
      rdb.ref("/curriculo/2021S2/inscricoesUCD/"+this.userLog.id+"/atuouUC").set(this.atuouUC);
    },

    experienciasEADSel() {
      console.log(this.experienciasEAD);
      console.log(this.outrasExperienciasEAD);
      rdb.ref("/curriculo/2021S2/inscricoesUCD/"+this.userLog.id+"/experienciasEAD").set(this.experienciasEAD);
      rdb.ref("/curriculo/2021S2/inscricoesUCD/"+this.userLog.id+"/outrasExperienciasEAD").set(this.outrasExperienciasEAD);
    },

    outrasExperienciasEADChange() {
      console.log(this.outrasExperienciasEAD);
      rdb.ref("/curriculo/2021S2/inscricoesUCD/"+this.userLog.id+"/outrasExperienciasEAD").set(this.outrasExperienciasEAD);
    },

    lattesSel() {
      console.log(this.lattes);
      rdb.ref("/curriculo/2021S2/inscricoesUCD/"+this.userLog.id+"/lattes").set(this.lattes);
    }

  }
}
</script>

<style scoped>
</style>
