<template>
  <span>
    <span v-if="sigla2 != ''" :key="sigla">
      <i v-i:ar##16="areas[sigla2].icon" class="" :style="'color: '+areas[sigla2].cor+'; fill: '+areas[sigla2].cor"/>
      <span v-c:B class="ms-1 f-roboto fw-400 rounded" :style="'font-size: 6.5pt; padding-left: 2px; padding-top: 1px; padding-right: 2px; backgroundColor: '+areas[sigla2].cor">{{ areas[sigla2].sigla }}</span>
    </span>
  </span>
</template>

<script>
import { rdb } from '@/firebasedb.js';

export default {
  name: "tagArea",
  props: {
    sigla: { default: "", type: String },
    areaID: { default: "", type: String },
  },
  data: function() {
    return {
      areas: {
        "AU&D": { cor: "#933b4a", title: "Arquitetura e Urb. & Design", sigla: "AU&D", collection: "ar", icon: "arquitetura2", link: "formacoes" },
        "CA&MA": { cor: "#c3b000", title: "C. Agrárias & Meio Ambiente", sigla: "CA&MA", collection: "ar", icon: "agraria2", link: "formacoes" },
        "CB&S": { cor: "#2c6500", title: "Ciências Biológicas e Saúde", sigla: "CB&S", collection: "ar", icon: "saude", link: "formacoes" },
        "CH": { cor: "#8407a3", title: "Ciências Humanas",  sigla: "CH",collection: "ar", icon: "humanas", link: "formacoes" },
        "CJ": { cor: "#6b1e61", title: "Ciências Jurídicas",  sigla: "CJ", collection: "ar", icon: "balance2", link: "formacoes" },
        "C&A": { cor: "#c14c02", title: "Comunicação & Artes",  sigla: "C&A", collection: "ar", icon: "comunicacao", link: "formacoes" },
        "ENG": { cor: "#000320", title: "Engenharias", sigla: "ENG", collection: "ar", icon: "engineering", link: "formacoes" },
        "G&N": { cor: "#4f2f09", title: "Gestão & Negócios", sigla: "G&N", collection: "ar", icon: "gestao", link: "formacoes" },
        "TI&C": { cor: "#526383", title: "TI & Computação", sigla: "TI&C", collection: "ar", icon: "tic", link: "formacoes" },
        "T&H": { cor: "#b63838", title: "Turismo & Hospitalidade", sigla: "T&H", collection: "ar", icon: "turismo", link: "formacoes" },
        "CORE": { cor: "#ff5400", title: "Core Curriculum", sigla: "CORE", collection: "ar", icon: "core", link: "formacoes" },
        "MARG": { cor: "#ff5400", title: "Margarida", sigla: "MARG", collection: "ar", icon: "margarida", link: "formacoes" },
        "VIOL": { cor: "#681e6a", title: "Violeta", sigla: "VIOL", collection: "ar", icon: "margarida", link: "formacoes" },
        "ROSA": { cor: "#e000ec", title: "Rosa", sigla: "ROSA", collection: "ar", icon: "margarida", link: "formacoes" },
        "LIR": { cor: "#d1bc00", title: "Lirio", sigla: "LIR", collection: "ar", icon: "margarida", link: "formacoes" },
        "GERB": { cor: "#009f87", title: "Gérbera", sigla: "GERB", collection: "ar", icon: "margarida", link: "formacoes" },
      },
      sigla2: "",
    }
  },
  watch: {
    sigla() {
      this.build();
    },
    areaID() {
      this.build();
    }
  },
  created: function() {

  },
  mounted: function() {
    var self = this;
    //console.log("tagarea Mounted");
    //console.log(this.siglas);
    this.build();
  },
  methods: {

    build() {
      var self = this;
      //console.log("tagarea Mounted");
      //console.log(this.siglas);
      if(this.sigla == "") {
        if(this.areaID != "") {
          rdb.ref("/curriculo/areas/"+this.areaID).once("value",function(snapshot) {
              if(snapshot.val() != null) {
                self.sigla2 = snapshot.val().sigla;
              }
          });
        }
      } else {
        this.sigla2 = this.sigla;
      }
    }

  }
}
</script>

<style scoped>

</style>
