<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-toolbar class="ps-2" :color="cor.active.b" dense>
          <v-btn class="me-0 pe-0" icon small v-bg:B>
            <v-icon>mdi-exclamation</v-icon>
          </v-btn>
          <div class="ms-3">
            <span class="ms-0 f-roboto fw-400 fs-11pt">
              Atualização de perfil
            </span>
          </div>
          <v-spacer></v-spacer>
          <v-toolbar-items>
          </v-toolbar-items>
        </v-toolbar>
        <v-container fluid class="mb-0">
          <v-row no-gutters align="center" justify="space-between" class="p-0 m-0" style="height: 100%">
            <v-col cols="12" md="5" class="p-0 m-0">
              <v-card class="px-2" flat>
                <svgupdate size="fit" />
              </v-card>
            </v-col>
            <v-col cols="12" md="7" class="p-0 m-0">
              <v-card class="p-1 m-0 text-center" flat>
                <p class="fs-10pt f-raleway m-0 mb-1 p-0">
                  <span class="fs-11pt f-raleway">Olá</span>
                  <span class="ms-1 f-roboto fs-10pt fw-500">{{userLog.nome}}</span>
                </p>
                <p class="fs-10pt f-raleway m-0 my-1 p-0">Seja bem-vindo(a) ao </p>
                <v-img class="p-0 w140 mx-auto mt-2" src="https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Flogos%2Flogo-800.png?alt=media&token=ff063aea-169b-4c6b-86ec-bc872bffeab1"></v-img>
                <p class="fs-10pt f-raleway m-0 my-2 p-0">
                  Notamos que talvez alguns dos seus dados de perfil não estejam completos e/ou corretamente atualizados no nosso sistema.
                </p>
                <p class="fs-10pt f-raleway m-0 mt-2 mb-0 p-0">
                  Esta atualização é muito importante e tem como objetivo garantir que todos possuam as informações necessárias para fins de acesso as funcionalidades do novo sistema.
                </p>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-card class="p-1 pt-2 mt-n2">
          <v-col cols="12" class="mt-0 mb-0 py-0" v-if="tipo=='email'">
            <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">Email institucional do usuário</p>
            <v-text-field class="p-0 m-0" v-model="formEmail" :rules="[rules.required, rules.email]" solo dense label="Email" required placeholder="Digite o email"></v-text-field>
          </v-col>
          <v-col cols="12" class="mt-0 mb-0 py-0" v-if="tipo=='cpf'">
            <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">CPF do usuário</p>
            <v-text-field class="p-0 m-0" v-model="formCPF" :rules="[rules.required]" solo dense label="CPF" required placeholder="Digite o cpf"></v-text-field>
          </v-col>
          <v-col cols="12" class="mt-n3 mb-2 py-0 text-center">
            <v-btn color="" small outlined v-c:I @click="cadastrar">
              <i v-i:ic#save#18 class="btn-icon-left"></i>
              Cadastrar
            </v-btn>
          </v-col>
        </v-card>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import svgupdate from "@/components/svgs/svg-update.vue";

export default {
  name: "atualizarusuario",
  components: { svgupdate },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      dialog: false,
      formEmail: "",
      formCPF: "",
      tipo: "email",
      rules: {
        required: value => !!value || 'Obrigatório',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
    },

    show(tipo) {
      this.tipo = tipo;
      this.dialog = true;
    },

    validarCPF(cpf){
      if (typeof cpf !== "string") return false
      cpf = cpf.replace(/[\s.-]*/igm, '')
      if (
        !cpf ||
        cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999"
      ) {
        return false
      }
      var soma = 0
      var resto
      for (var i = 1; i <= 9; i++)
      soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
      resto = (soma * 10) % 11
      if ((resto == 10) || (resto == 11))  resto = 0
      if (resto != parseInt(cpf.substring(9, 10)) ) return false
      soma = 0
      for (var i = 1; i <= 10; i++)
      soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i)
      resto = (soma * 10) % 11
      if ((resto == 10) || (resto == 11))  resto = 0
      if (resto != parseInt(cpf.substring(10, 11) ) ) return false
      return true
    },

    cadastrar() {
      if(this.tipo == "email") {
        if(this.formEmail == "") {
          this.toastD("Email obrigatório!");
          return;
        }
        var pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!pattern.test(this.formEmail)) {
          this.toastD("Email inválido!");
          return;
        }
        var updates = {};
        updates["/usuarios/pessoas/"+this.userLog.id+"/email"] = this.formEmail;
        //console.log("updates",updates);
        rdb.ref().update(updates);
        this.toastS("Dados cadastrados com sucesso!");
        this.dialog = false;
        var user = this.userLog;
        user.email = this.formEmail;
        this.$store.commit("updateUser", user);
        this.$router.push( { path: "profile" });
      }
      if(this.tipo == "cpf") {
        var cpfValue = this.formCPF.trim().replaceAll(".","").replaceAll("-","");
        //console.log(cpfValue);
        if(cpfValue == "") {
          this.toastD("CPF obrigatório!");
          return;
        }
        //cpfValue = "" + parseInt(cpfValue);
        //console.log(cpfValue);
        if(!this.validarCPF(cpfValue)) {
          this.toastD("CPF inválido!");
          return;
        }
        //console.log("cpf ok!");
        var updates = {}; //55677126187
        updates["/usuarios/pessoas/"+this.userLog.id+"/cpf"] = cpfValue;
        //console.log("updates",updates);
        rdb.ref().update(updates);
        this.toastS("Dados cadastrados com sucesso!");
        this.dialog = false;
        var user = this.userLog;
        user.cpf = "" + parseInt(this.formCPF);
        this.$store.commit("updateUser", user);
        this.$router.push( { path: "profile" });
      }
    }
  }
}
</script>

<style scoped>
</style>
