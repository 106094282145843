<template>
  <span class="">
    <v-btn v-if="coments.length >0" small @click="$emit('comentsClick')" class="m-0 p-0 me-0" icon>
      <i class="btn-icon-left" v-cHex="color" v-i:duo#chat-circle-text#18></i>
    </v-btn>
    <v-btn v-if="coments.length >0" v-cHex="color" @click="$emit('comentsClick')" class="m-0 ms-0 mb-0 p-0 text-nonecase text-lowercase" small plain text>
      <span class="me-0 f-raleway fs-9pt fw-500">
        <span class="ms-0">{{coments.length}} comentário</span><span v-if="coments.length>1">s</span>
      </span>
    </v-btn>
  </span>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';

export default {
  name: "comments",
  components: {},
  props: {
    color: { default: "#000000", type: String },
    uc: { default: null, type: Object },
    rdbPath: { default: "/pathNotDefined", type: String },
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      coments: [],
      textoComent: "",
      createdAt: "",
      commentblocked: true,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //console.log("this.color",this.color);

    rdb.ref(this.rdbPath+"/coments").on('value',function(snapshot) {
      var ret = snapshotValToArray(snapshot.val());
      //console.log("coments");
      //console.log(ret);
      self.coments = ret;
    });
  },
  methods:{

    build() {
      var self = this;
    },

    showComents(focus) {
      var self = this;
      //console.log("showComents");
      if(!this.comentsEnabled) {
        this.comentsEnabled = true;
        this.eventBus.$emit("closeAllComents");
        if(focus) {
          this.$nextTick(function() {
            self.$refs.txtcomment.focus();
          });
        }
      } else {
        if(focus) {
          this.$nextTick(function() {
            self.$refs.txtcomment.focus();
          });
        } else {
          this.eventBus.$emit("closeAllComents");
          this.comentsEnabled = false;
        }
      }
    },

  }
}
</script>

<style scoped>
</style>
