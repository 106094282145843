<template>
  <v-card class="" style="max-height:80vh;">
    <div class="pt-0 pb-0 my-1" v-if="false & confirmaInscricao">
      <v-alert type="success" class="m-0 p-1 px-3">
        Inscrição enviada com sucesso!
        <p class="m-0 p-0 fs-11pt fw-800">
          Você poderá editar, ajustar e ao enviar novamente a inscrição será alterada.
        </p>
      </v-alert>
    </div>
    <div class="pt-0 pb-0 my-1" v-if="true">
      <v-alert type="error" class="m-0 p-1 px-3">
        Inscrições encerradas!
        <p class="m-0 p-0 fs-11pt fw-800">
          Em breve você receberá novas informações.
        </p>
      </v-alert>
    </div>

    <v-stepper dense v-model="passo" style="background-color: #f1ebf5;">
      <v-stepper-header>
        <v-stepper-step step="1" editable>
          Início
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="2" >
          Confirmação
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3">
          Docência
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="4">
          UCDs
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="5">
          Experiência
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="6">
          Envio
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items
      <v-stepper-content step="1" class="pt-2">
        <step01 @proximo="passo = 2"/>
      </v-stepper-content>
      <v-stepper-content step="2"class="pt-2">
        <step02 @anterior="passo = 1" @proximo="passo = 3"/>
      </v-stepper-content>
      <v-stepper-content step="3"class="pt-2">
        <step03 @anterior="passo = 2" @proximo="passo = 4"/>
      </v-stepper-content>
      <v-stepper-content step="4"class="pt-2">
        <step04 @anterior="passo = 3" @proximo="passo = 5"/>
      </v-stepper-content>
      <v-stepper-content step="5"class="pt-2">
        <step05 @anterior="passo = 4" @proximo="passo = 6"/>
      </v-stepper-content>
      <v-stepper-content step="6"class="pt-2">
        <step06 @anterior="passo = 5" @proximo="finalizar"/>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</v-card>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import step01 from "./step01.vue";
import step02 from "./step02.vue";
import step03 from "./step03.vue";
import step04 from "./step04.vue";
import step05 from "./step05.vue";
import step06 from "./step06.vue";

export default {
  name: "stepers",
  components: { step01, step02, step03, step04, step05, step06 },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      passo: 1,
      confirmaInscricao: false,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
    rdb.ref('/curriculo/2021S2/inscricoesUCD/'+this.userLog.id).on('value',function(snapshot) {
      self.inscricao = snapshot.val();
      if(self.inscricao == null) {
        self.confirmaInscricao = false;
      } else {
        self.confirmaInscricao = self.inscricao.confirmaInscricao;
      }
      console.log("self.inscricao",self.inscricao);
      //self.build();
    });

  },
  methods:{

    build() {
      var self = this;
    },

    finalizar() {

    }
  }
}
</script>

<style scoped>
</style>
