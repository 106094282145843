<template>
  <div class="m-0 mb-2 p-0" v-if="visible">
    <v-card outlined>
      <v-btn @click="visible = false" class="absolute right-0 top-0 me-2" color="" icon small>
        <i v-i:duo#x-circle#20></i>
      </v-btn>
      <div class="p-1 pt-3 pb-2 my-0 text-center">
        <v-img class="mx-auto" width="45%" src="https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Flogos%2Flogo-800.png?alt=media&token=ff063aea-169b-4c6b-86ec-bc872bffeab1"></v-img>
        <p class="mt-1 mb-0 text-black fs-10pt fw-300 f-raleway text-center">Sistema de apoio à integração curricular</p>
        <p class="text-center f-sanspro fw-300 fs-9pt m-0 pt-0 mt-0">VPA - Vice Presidência Acadêmica</p>
        <p class="my-0 text-center fs-7pt f-lato fw-300"><span class="me-1 fw-400">Versão:</span> 0.1.2 (alfa beta gama)</p>
        <v-btn @click="dialog = true" class="text-nonecase px-1 mb-0 py-0 mt-2 f-titillium" outlined x-small>sobre AppIntegra</v-btn>
      </div>
    </v-card>
    <v-dialog width="600" v-model="dialog">
      <v-card class="p-2" outlined>
        <v-card-text class="text-center text-justify">
          <v-img class="mx-auto mt-3 ps-1" width="40%" src="https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Flogos%2Flogo-800.png?alt=media&token=ff063aea-169b-4c6b-86ec-bc872bffeab1"></v-img>
          <p class="text-black fs-13pt fw-500 f-raleway text-center mt-2 mb-0">Sistema de apoio à integração curricular</p>
          <p class="text-center text-muted fs-8pt m-0 pt-1">VPA - Vice Presidência Acadêmica</p>
          <p class="mb-2 text-center fs-10pt f-yanone"><strong class="me-2">Versão:</strong> 0.1.2 (alfa beta gama)</p>
          <div class="text-center m-0 p-0">
            <p class="text-black f-raleway fs-12pt fw-500 f-raleway pt-0 mt-0 mb-3">
              Últimas funcionalidades atualizadas:
            </p>
            <p class="text-black f-raleway fs-10pt fw-300 f-lato text-left my-0 mt-1">
              - Indicação de metas / conteúdos / acordos para avaliação A2
            </p>
            <p class="text-black f-raleway fs-10pt fw-300 f-lato text-left my-0 mt-1">
              - Cursos / Planos de Ensino
            </p>
            <p class="text-black f-raleway fs-10pt fw-300 f-lato text-left my-0 mt-1">
              - Construção colaborativa de Tópicos / Metas / Desempenhos / Competências - TODAS AS ÁREAS
            </p>
            <p class="text-black f-raleway fs-10pt fw-300 f-lato text-left my-0 mt-1">
              - Visualização dos relatos de experiência
            </p>
            <p class="text-black f-raleway fs-10pt fw-300 f-lato text-left my-0 mt-1">
              - Calendário acadêmico
            </p>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import { notification } from '@/components/notification/notification.js'
import dummy from '@/components/dummy/dummy.vue'
import { faker } from '@/components/dummy/faker.js'

export default {
  name: 'sobre',
  components: { dummy },
  computed: {
  },
  data () {
    return {
      dialog: false,
      visible: true,
    }
  },
  methods: {

  }
}
</script>
